$( document ).ready( function () {

	var myPlaylist = new jPlayerPlaylist( {
		jPlayer: '#jquery_jplayer_N',
		cssSelectorAncestor: '#jp_container_N'
	}, [
		{
			title: 'Straight To Hell',
			artist: 'Ozzy Osbourne',
			mp3: '../dist/mp3/01. Straight To Hell.mp3',
		},
		{
			title: 'All My Life',
			artist: 'Ozzy Osbourne',
			mp3: '../dist/mp3/02. All My Life.mp3',
		},
		{
			title: 'Goodbye',
			artist: 'Ozzy Osbourne',
			mp3: '../dist/mp3/03. Goodbye.mp3',
		},
		{
			title: 'Ordinary Man (feat. Elton John)',
			artist: 'Ozzy Osbourne',
			mp3: '../dist/mp3/04. Ordinary Man (feat. Elton John).mp3',
		},
		{
			title: 'Under The Graveyard',
			artist: 'Ozzy Osbourne',
			mp3: '../dist/mp3/05. Under The Graveyard.mp3',
		},
		{
			title: 'Eat Me',
			artist: 'Ozzy Osbourne',
			mp3: '../dist/mp3/06. Eat Me.mp3',
		},
		{
			title: 'Today Is The End',
			artist: 'Ozzy Osbourne',
			mp3: '../dist/mp3/07. Today Is The End.mp3',
		},
		{
			title: 'Scary Little Green Men',
			artist: 'Ozzy Osbourne',
			mp3: '../dist/mp3/08. Scary Little Green Men.mp3',
		},
		{
			title: 'Holy For Tonight',
			artist: 'Ozzy Osbourne',
			mp3: '../dist/mp3/09. Holy For Tonight.mp3',
		},
		{
			title: 'Its A Raid (feat. Post Malone)',
			artist: 'Ozzy Osbourne',
			mp3: '../dist/mp3/10. Its A Raid (feat. Post Malone).mp3',
		},
		{
			title: 'Take What You Want (feat. Ozzy Osbourne & Travis Scott)',
			artist: 'Ozzy Osbourne',
			mp3: '../dist/mp3/11. Take What You Want (feat. Ozzy Osbourne & Travis Scott).mp3',
		},
	], {
		playlistOptions: {
			autoPlay: true,
			enableRemoveControls: false,
			loopOnPrevious: true
		},
		loop: true,
		swfPath: '/swf',
		supplied: 'mp3',
		smoothPlayBar: true,
		keyEnabled: true,
		useStateClassSkin: true,
		audioFullScreen: true // Allows the audio poster to go full screen via keyboard
	} );
} );